/* generic */

.clearfix {
	@include clearfix;
}

.clearboth {
	clear: both;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

.container {
	margin: 0 auto;
	max-width: 1200px;
}

textarea,
button,
input,
input[type="text"],
input[type="button"],
input[type="submit"] {
     -webkit-appearance: none;
     border-radius: 0;
     outline: none;
}

	// js responsive states

	.state-indicator {
		position: absolute;
		top: -999em;
		left: -999em;
		z-index: 1;
	}

	/* tablet */
	@media all and (max-width: 992px) {
		
		.state-indicator {
			z-index: 7;
		}
	}