/*
	Author: Ninjas For Hire
	Author URL: http://www.ninjasforhire.co.za

	Made in Cape Town :)
*/

// required
@import "bootstrap/mixins/clearfix";
@import "bootstrap/mixins/responsive-visibility";
@import "inc/vars";
@import "inc/font";
@import "inc/base";

// bootstrap
@import "bootstrap/responsive-utilities";
@import "bootstrap/mixins/grid-framework";
@import "bootstrap/mixins/grid";
@import "bootstrap/grid";

// helpers
@import "helpers/generic";
@import "helpers/owl";
@import "helpers/uniform";
@import "helpers/remodal";

// templates
@import "partials/header/header";
@import "partials/footer/footer";

@import "partials/content/home";
@import "partials/content/contact";