
	body{
		padding-top:98px;

		@media (max-width:600px){
				padding-top:60px;

		}
	}

	.header {
		display: block;
		background: black;
		position: relative;
		width:100%;
		position: fixed;
		z-index:99;
		top:0;
		left:0;

		.inner{
			padding-right:210px;
		}

		h1{
			display: inline-block;
			float:left;
			width:207px;
			height:98px;
			background: url(../images/logo.jpg) no-repeat center center / auto 100%;

			@media (max-width:600px){
				height:60px;
				width: 127px;
			}

			a{
				display: block;
				width:100%;
				height:100%;
				text-indent:-9999px;
				overflow:hidden;
			}
		}

		.call_us{
			display: inline-block;
			padding:21px 20px 20px 20px;
			color:white;
			float:right;
			font-size:18px;
			font-weight:bold;
			text-align:Center;
			background: #7cbf00;
			position: absolute;
			right:0;
			top:0;

			@media (max-width:600px){
				font-size:14px;
				    padding: 9px;
			}

			a{
				font-size:24px;
				text-decoration:none;
				display: block;
				color:white;

				@media (max-width:600px){
					font-size:18px;
				}
			}

		}
	}

	.mobilenav{
		display:none;

		@media (max-width:1070px){
	        display:block;
	        width:50px;
	        height:50px;
	        border:0;
	        background: transparent url(../images/mobile_nav.png) no-repeat center center;
	        position: absolute;

	       right:200px;
	       top:50%;
	       margin-top:-25px;
	       cursor:pointer;
	    }

	    @media (max-width:600px){
				right:150px;
			}
	}

	.menu{
		display: inline-block;
		float:right;
		margin-top: 22px;
		margin-right:20px;

		.opensub{
			display:none;

			@media (max-width:1070px){
				display:block;
				width:40px;
				height:49px;
				z-index:9;
				background: url(../images/plus.png) no-repeat center center;
				position: absolute;
				right:0;
				top:0;
				border:0;

				&.open{
					background: url(../images/minus.png) no-repeat center center;
				}
			}

		}

		@media (max-width:1070px){
	        display:none;

	        &.open{
	        	display:block;
	        	position: fixed;
	        	top:98px;
	        	left:0;
	        	width:100%;
	        	background: black;
	        	margin: 0;
	        	z-index: 9;

	        	@media (max-width:600px){
					top:60px;
				}

	        	li{
	        		display:block;
	        		border-top:1px solid #ccc;
	        		text-align:center;
	        	}
	        }
	    }

	      @media (max-width:600px){
	      	&.open{
				top:60px;
			}
			}

		li{
			display:inline-block;
			position: relative;

			

			&:hover{
				ul{
					display:block;

					@media (max-width:1070px){
						display:none;
					}
				}
			}

			ul {
				display:none;
				position: absolute;
				top:50px;
				left:0;
				width:250px;
				background: black;
				padding-top:25px;

				@media (max-width:1070px){
					position: relative;
					width:100%;

					&.open{
						display: block;
						top: inherit;
						left: inherit;
						padding: 0;
					}
				}



				li{

				}
			}

			a{
				font-size:16px;
				font-weight:bold;
				padding:15px;
				color:white;
				display: block;
				text-decoration:none;
				transition: color 0.3s;
				-webkit-transition: color 0.3s;

				@media (max-width:1200px){
			        font-size:14px;
			        padding:15px 10px;
			    }


				&:hover, &.current{
					color:#92df00;
				}
			}
		}
	}

	