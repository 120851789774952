	.hero{
		display: block;
		height:220px;
		width:100%;

		h1{
			    color: white;
			    font-size: 40px;
			    font-weight: 300;
			    padding: 15px;
			    font-weight: 300;
			    background: url(../images/opacity.png) repeat;
			    display: inline-block;
			    border-radius: 3px;

			@media (max-width:$screen-md){
			      	font-size:35px;
				}


		}

		&--big{
			height:445px;


			h1{
				max-width: 950px;
				width: 90%;
				display: block;
				margin:0 auto 30px auto;
				font-size:40px;
				background: none;
			}
		}
	}

	.request_callback{
		background: url(../images/opacity.png) repeat;
		border-radius:3px;
			-webkit-border-radius:3px;
			width:90%;
			max-width:600px;
			padding:25px;
			margin:auto;

			ul{
				width:50%;
				display:block;
				float:left;

				@media (max-width:600px){
			      	display:none;
				}

				li{
					display: block;
					padding-left:30px;
					background: url(../images/tick.png) no-repeat left center;
					color:white;
					font-size:17px;
					font-style:italic;
					text-align:left;
				}
			}

			a{
				color: rgb(51, 51, 51);
				font-size: 20px;
				font-style: italic;
				margin-bottom: 30px;
				width: 100%;
				padding: 15px 30px;
				background: white;
				border-radius: 3px;
				border-width: 0px;
				border-style: initial;
				border-color: initial;
				border-image: initial;
				font-style: normal;
				font-size: 18px;
				color: black;
				font-weight: 600;
				cursor: pointer;
				margin: 10px 0;
				background: #7cbf00;
				text-decoration: none;
				display: inline-block;
				width: Auto;
				float: right;

				@media (max-width:600px){
			      	float:none;
			      	margin:auto;
				}
			}

	}

	.main_content{
		display: block;
		overflow:hidden;

		img{
			max-width:100%;
			height:auto;
		}

		&__copy{
			padding:30px;
			padding-right:30px;

			@media (max-width:1430px){
		        padding: 30px;
		    }

		    @media (max-width:700px){

		       table{
		       	display:block!important;
		       	tbody{
		       		display:block!important;
			       	td{
			       	display:block!important;
			       	width:100%!important;
			       	padding:0;

			       	br{
			       		display:none!important;
			       	}
			       	ul{
			       		margin-bottom:0!important;
			       	}
			       }
			   	}
			   }
		    }


			h2{
				font-weight:bold;
				font-size:30px;
				margin-bottom:20px;

				@media (max-width:$screen-md){
			      	font-size:22px;
				}
			}
			.introtext{
				font-size:24px;
				margin-bottom:20px;

				@media (max-width:$screen-md){
			      	font-size:18px;
				}
			}
			img{
				display: block;
				margin:auto;
			}

			p{
				margin-bottom:30px;
			}

			ul{
				margin-bottom:30px;
				li{
					padding-left:30px;
					background: url(../images/bullet-arrow.jpg) no-repeat left center;
					margin-bottom:5px;
				}
			}
		}

		&__sidebar{
			

			@media (max-width:1430px){
		        padding: 0;
		    }

			.call_us{
				padding:20px;
				text-align:center;

				h3{
					font-size:30px;
					margin-bottom:40px;

					@media (max-width:$screen-md){
			      	font-size:22px;
				}
				}
				a{
					padding:15px 35px 15px 10px;
					background: white url(../images/download_btn.jpg) no-repeat right 5px center;
					border-radius:3px;
					color:#7ea930;
					font-size:20px;
					margin-bottom:30px;
					width:100%;
					border:2px solid #7ea930;
					font-weight:600;
					text-decoration:none;
					    display: inline-block;
    width: auto;
				}
			}

		}
	}

	.contact_frm{
		display: block;
		    padding: 30px 40px 40px 40px;
		background: #e5e5e5 url(../images/contact_frm_bg.jpg) no-repeat left top;
		position: relative;

		&:after{
			content:'';
			height:100%;
			width:500%;
			display: block;
			background: #e5e5e5;
			position: absolute;
			right:-500%;
			top:0;
			position: absolute;
		}

		h3{
			font-size:30px;
			font-weight:normal;
			margin-bottom:20px;

			@media (max-width:$screen-md){
			      	font-size:22px;
				}
		}

		fieldset{
			border:0;
			padding:0;
			margin:0;
		}

		:after{
			display:none;
		}

		.frm_error_style, .frm_error{
			color:red;
			font-size:12px;
			padding: 5px 0;
		}

		.frm_message{
			color:#7cbf00;
		}

		input, textarea{
			padding:15px;
			background: white;
			border-radius:3px;
			color:#333333;
			font-size:17px;
			font-style:italic;
			margin-bottom:30px;
			width:100%;
			border:0;

			&[type=submit]{
				margin:0;
				background: #7cbf00;
				font-style:normal;
				font-size:18px;
				color:white;
				font-weight:600;
				cursor:pointer;

				@media (max-width:1200px){
			      	font-size:14px;
				}
			}
		}
		textarea{
			    height: 136px;
    display: block;
		}
	}

	.testimonial{
		display: block;
		background: #95c739 url(../images/green_triangle.jpg) no-repeat center bottom;
		position: relative;
		min-height:350px;
		overflow:hidden;

		&--contact_team{
			background:white;

			h3{
				font-size:30px;
				font-weight:normal;
			}
			.testimonial__text p{
				font-size:17px;
			}
			.testimonial__img{
				left: calc(50% - 300px);
				width: calc(50% + 300px);

				@media (max-width:1300px){
					left: calc(50% - 100px);
				}

				@media (max-width:$screen-md){
					width:100%;
					left:0;
				}


			}
		}

		&__img{
			display: block;
			width:50%;
			height:100%;
			position: absolute;
			right:0;
			top:0;

			@media (max-width:$screen-md){
			      display: block;
				    width: 100%;
				    height: 250px;
				    position: relative;
				    right: inherit;
				    top: inherit;

				    &--badge{
				    	background-size:auto 90%!important;
				    }
				}

		}
		&__text{
			padding:30px ;

			@media (max-width:1430px){
		        padding: 30px;
		    }

			span{
				font-size:20px;
				color:black;
				margin-bottom:30px;
				font-weight:bold;
				display: block;
			}
			p{
				font-size:30px;
				font-weight:300;
				color:black;
				margin-bottom:20px;

				@media (max-width:$screen-md){
			      	font-size:22px;
				}
			}
			small{
				display: block;
				font-size:20px;
				font-weight:normal;
				margin-bottom:10px;
				color:black;

				&.testimonial__name{

				}
			}
		}
	}


	.liketable{
		display:table;
		width:100%;
		height:100%;

		.likecell{
			display:table-cell;
			vertical-align:middle;
			text-align:Center;
		}
	}


	.services_block{
		overflow:hidden;


		&__item{
			padding:50px;
			color:white;
			text-align:Center;
			position: relative;
			overflow:hidden;

			&:after{
				content:'';
				width:100%;
				height:189px;
				background: url(../images/service_bg.png) no-repeat center top;
				position: absolute;
				top:0;
				left:0;
				z-index:0;
			}

			h3{
				font-size:30px;
				font-weight:bold;
				margin-bottom:20px;	
				position: relative;
				z-index:1;
			}
			p{
				font-size:16px;
				font-weight:600;
				max-width:250px;
				margin:0 auto 80px auto;
				position: relative;
				z-index:1;
			}
			a{
				color: rgb(51, 51, 51);
				font-size: 20px;
				font-style: italic;
				margin-bottom: 30px;
				width: 100%;
				padding: 15px 30px;
				background: white;
				border-radius: 3px;
				border-width: 0px;
				border-style: initial;
				border-color: initial;
				border-image: initial;
				font-style: normal;
				font-size: 18px;
				color: black;
				font-weight: 600;
				cursor: pointer;
				margin: 10px auto;
				background: #7cbf00;
				text-decoration: none;
				display: inline-block;
				width: Auto;
				position: relative;
				z-index:1;
			}
		}
	}
