	.contact_frm_map{
		position: relative;
		background: #e5e5e5 url(../images/contact_frm_bg.jpg) no-repeat left top;

		@media (max-width:$screen-md){
				.testimonial__img{
					display:none;
				}
			}

		&--about{
			background: white;


			.contact_frm{
				background:transparent;
				margin-bottom:30px;
				position: relative;

				@media (max-width:$screen-md){
			        display:table;
			        width:100%;

			        .contact_frm__left{
						display:table-cell;
						float:none;
			        }
			        .contact_frm__right{
						display:table-cell;
						float:none;
			        }

			    }

			    @media (max-width:700px){
			    	padding-bottom:80px;


			    	input[type=submit]{
			    		position: absolute;
			    		bottom: 30px;
			    		left: 40px;
			    		right: 40px;
			    		width: calc(100% - 80px);
			    		display: inline-block;
			    	}

			        	.contact_frm__left{
							display:block;
							width:100%;
							float:none;
							padding:0;

				        }
				        .contact_frm__right{
							display:block;
							width:100%;
							float:none;
							padding:0;
				        }

			        }
			}

		}

		.aboutcontent{
			padding: 50px 30px 50px 30px;

			@media (max-width:1430px){
		        padding: 50px 30px;
		    }


			h3{
				font-size:30px;
				margin-bottom:20px;
				font-weight:normal;

					@media (max-width:$screen-md){
			      	font-size:25px;
				}
			}
			p{
				font-size:26px;
				font-weight:300;
				margin-bottom:30px;

					@media (max-width:$screen-md){
			      	font-size:22px;
				}
			}
			a{
				padding: 15px 35px 15px 10px;
				background: white url(../images/download_btn.jpg) no-repeat right 5px center;
				border-radius: 3px;
				color: #7ea930;
				font-size: 20px;
				margin-bottom: 30px;
				width: 278px;
				border: 2px solid #7ea930;
				font-weight: 600;
				text-decoration: none;
				margin:auto;
				display:block;
			}
		}

		.contact_frm{
			background: transparent;

			@media (max-width:$screen-md){
		      	background: #e5e5e5;
		      	margin:0;
			}


			&:after{
					display:none;
				}

		}
	}


	.contact_frm{

		p{
			margin-bottom:20px;
			font-size:18px;
		}

			&__left{
				float:left;
				width:50%;
				display: block;
				padding-right:15px;

				@media (max-width:650px){
			      	padding:10px;
			      	width:100%;
				}
			}
			&__right{
				float:left;
				width:50%;
				display: block;
				padding-left:15px;

				@media (max-width:650px){
			      	padding:10px;
			      	width:100%;
				}
			}
		}