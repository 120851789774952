/* base */

html {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

*, *:before, *:after {
	-moz-box-sizing: inherit;
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

body {
	color: #333333;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	-webkit-text-stroke: 1px rgba(0,0,0,0);
}

img {
	border: 0;
}

h1, h2, h3, h4, h5, h6, p, form, input, ul, ol, li, header, nav, footer, section {
	list-style: none;
	margin: 0;
	padding: 0;
}

header, nav, footer, section {
	display: block;
}

input:focus,
textarea:focus {
	outline: none;
}

input,
textarea,
select,
option {
	color: #333;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 300;
}

textarea {
	resize: none;
	overflow: auto;
}

table,
table td {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 0;
	padding: 0;
}

a {
	color: #333;
}

.inner{
	display: block;
	width:1430px;
	max-width:100%;
	padding:0 15px;
	margin:auto;
}