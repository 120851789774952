
	.footer {
		&__nav{
			display: block;
			padding: 30px 30px 50px 30px;
			font-size:12px;

			p{
				padding: 0 10px;
			}

			h4{
				font-size:20px;
				margin-bottom:10px;

				@media (max-width:$screen-md){
					padding:10px;
				}
			}
			ul{
				padding: 0 10px;

				li{
					margin-bottom:5px;

					a{
						font-size:12px;
						text-decoration:none;
					}
				}
			}
		}
		&__bottom{
			display: block;
			color:white;
			font-weight:600;
			background: black;
			font-size:11px;
			padding:70px 0 50px 0;
			position: relative;
			overflow:hidden;


			&:before{
				content:'';
				display: block;
				position: absolute;
				top:-30px;
				left:-30px;
				background:white;
				transform: rotate(2deg);
				z-index:1;
				height:70px;
				width:150%;
			}
		}
	}

	.footer_slider{
		display: block;
		padding:20px 30px;
		position: relative;

		.owl-nav.disabled{
			display:block!important;
		}

		.owl-prev{
			display: block;
			height:100%;
			width:30px;
			cursor:pointer;
			background: url(../images/left_arrow.jpg) no-repeat center center;
			position: absolute;
			left:0;
			top:0;

			&:hover{
				background: url(../images/left_arrow.jpg) no-repeat center center;
			}
		}
		.owl-next{
			display: block;
			height:100%;
			width:30px;
			cursor:pointer;
			background: url(../images/right_arrow.jpg) no-repeat center center;
			right:0;
			top:0;

			&:hover{
				background: url(../images/right_arrow.jpg) no-repeat center center;
			}
		}

		.owl-item{
			img{
				width:auto!important;
				margin:Auto;
			}
		}
	}